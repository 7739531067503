@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f8f9fa;
  color: #333;
  line-height: 1.6;
}

a {
  text-decoration: none;
}

.container {
  width: 100%;
  max-width: 1170px;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  box-shadow: 0px 3px 19px rgba(126, 160, 174, 0.36);
  overflow: hidden;
}

.header__inner {
  display: flex;
  align-items: center;
  max-width: 1440px;
}

.logo {
  display: flex;
  max-width: 130px;
  margin-right: 70px;
}

@media(max-width: 1440px) {
  .logo {
    margin-right: 40px;
  }
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 20px;
}

.nav {
  display: flex;
  flex-grow: 1;
}

.nav__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.nav__list {
  list-style: none;
  display: flex;
  justify-content: center;
}

.nav__list li {
  margin-right: 30px;
  font-size: 1.2rem;
}

.nav__list li a {
  position: relative;
  line-height: 130%;
  color: #000;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}

.nav__list li a::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s all ease;
}

.nav__list li a:hover::after {
  opacity: 1;
}

.lang-list {
  display: flex;
}

.lang-list__item {
  display: flex;
}

.lang-list__link {
  position: relative;
  line-height: 130%;
  font-size: 18px;
  text-transform: uppercase;
  color: #004faf;
}

.lang-list__link::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #004faf;
  pointer-events: none;
  opacity: 0;
  transition: 0.2s all ease;
}

.lang-list__item:not(:last-child)::after {
  display: flex;
  content: "";
  width: 1px;
  height: 100%;
  margin: 0 8px;
  background: #004faf;
}

.lang-list__link:hover::after {
  opacity: 1;
}

.nav__link-know {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #004faf;
  border-radius: 8px;
  color: #004faf;
  font-family: "Gilmor-600";
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition: 0.3s all ease;
}

.nav__link-know:hover {
  background-color: #004faf;
  color: #f8f9fa;
}

.burger-btn {
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: center;
  width: 34px;
  height: 30px;
  margin-left: auto;
}

footer {
  display: flex;
  justify-content: center;
  padding: 80px 20px;
  border-top: 4px solid #0062d5;
  border-bottom: 4px solid #0062d5;
}

.footer__inner {
  display: flex;
  justify-content: space-between;
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.title {
  line-height: 130%;
  font-size: 36px;
  font-weight: 500;
  color: #4d5158;
}

.footer__title {
  margin-bottom: 28px;
}

.footer__item {
  display: flex;
  flex-direction: column;
}

.footer__title-small {
  line-height: 130%;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 28px;
  color: #054fa7;
}

.footer__text {
  width: 100%;
  max-width: 450px;
  line-height: 130%;
  margin-bottom: 14px;
  font-weight: 400;
  font-size: 18px;
  color: #4d5158;
}

.footer__social-wrap {
  display: flex;
  margin-bottom: 60px;
}

.footer__social-link {
  display: flex;
  width: 56px;
  height: 58px;
}

.footer__social-link:not(:last-child) {
  margin-right: 14px;
}

.footer__social-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.footer__social-link:hover {
  padding: 2px;
  transition: 0.3s all ease-in-out;
}

.btn-accent {
  display: flex;
  width: max-content;
  height: max-content;
  padding: 28px 65px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #fff;
  background: radial-gradient(61.9% 61.9% at 50% 50%, #d00606 0%, #e10808 36.85%, #ff5c5c 100%);
  box-shadow: 0px 4px 19px rgba(255, 94, 94, 0.85);
  border-radius: 40px;
  transition: 0.3s all ease;
  margin: 20px auto 0;
}

.btn-accent span {
  margin-left: 15px;
}

.btn-accent:hover {
  box-shadow: unset;
}

.footer__bottom-item span {
  font-weight: 400;
  font-size: 18px;
  color: #4d5158;
}

.footer__bottom-item a {
  font-style: italic;
  text-decoration: none;
  color: red;
  font-weight: 400;
  font-size: 18px;
}

.footer__map {
  width: 100%;
  max-width: 670px;
  height: 100%;
  min-height: 300px;
}

.footer__map #myMap {
  width: 100%;
  height: 100%;
}

@media (max-width: 1440px) {
  .nav__list li {
    margin-right: 22px;
    font-size: 1rem;
  }
}

@media (max-width: 1320px) {
  .nav__inner {
    display: none;
  }

  .nav-menu li {
    padding: 2px !important;
  }
}

@media (max-width: 1200px) {
  .footer__map {
    align-self: flex-end;
    max-width: 50%;
    max-height: 400px;
  }

  .footer__title {
    font-size: 30px;
    margin-bottom: 18px;
  }

  .footer__title-small {
    font-size: 20px;
  }

  .footer__text {
    font-size: 14px;
  }

  .footer__social-wrap {
    margin-bottom: 30px;
  }

  .footer__social-link {
    width: 36px;
    height: 38px;
  }
}

@media (max-width: 999px) {
  .btn-accent {
    display: none;
  }
}

@media (max-width: 900px) {
  footer {
    padding: 40px 20px;
  }

  .footer__inner {
    flex-direction: column;
  }

  .footer__map {
    max-width: 100%;
    max-height: 400px;
  }

  .footer__contacts {
    margin-right: 0;
    margin-bottom: 20px;
  }
}