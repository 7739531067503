.opportun {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 100px 20px 0 20px;
}

.opportun__title {
  margin-bottom: 80px;
}

.container1 {
  width: 100%;
  max-width: 1170px;
}

.opportun__inner {
  position: relative;
  display: flex;
}

.opportun__card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.opportun__card {
  display: flex;
  width: 100%;
  min-height: 150px;
  perspective: 800px;
}

.opportun__card-inner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(98, 146, 162, 0.28);
  transition: 0.4s all ease;
  transform-style: preserve-3d;
}

.opportun__card-face {
  display: flex;
  background: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.opportun__card-icon {
  min-width: 80px;
  margin-right: 20px;
}

img {
  border-style: none;
}

.opportun__card-text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.opportun__card-title {
  width: 100%;
  line-height: 130%;
  margin-bottom: 10px;
  font-size: 23px;
  color: #000;
  transition: 0.3s all ease;
}

.opportun__card-text {
  line-height: 130%;
  font-size: 12px;
  color: #000;
  transition: 0.3s all ease;
}

/* Card back */

.opportun__card-face, .opportun__card-back {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
}

.opportun__card-back {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background: #288bff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateX(180deg);
}

.opportun__card-back .opportun__card-icon, .opportun__card-back .opportun__card-title, .opportun__card-back .opportun__card-text {
  filter: brightness(100);
  color: #fff;
}

.opportun__card:hover .opportun__card-inner {
  transform: rotateX(180deg);
}