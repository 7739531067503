.main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 20px;
}

.intro {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 60px;
}

.intro__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.intro__img-container {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 400px;
  max-height: 650px;
}

.intro__img-container::before {
  z-index: 2;
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 20px;
  background: #fff;
}

.intro__img-wrap {
  position: relative;
  display: flex;
  width: 33.3333333333%;
}

.intro__img-wrap::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(251, 98, 101, 0.35) 0%, rgba(125, 97, 167, 0.35) 100%);
  transition: 0.3s;
}

.intro__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.intro__btn-container {
  z-index: 3;
  position: absolute;
  bottom: 0;
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1700px;
}

.intro__btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25.6666666667%;
  height: 4em;
  color: #1f74db;
  font-size: clamp(14px, 2.0vw, 34px);
  line-height: 130%;
  text-align: center;
  transition: 0.3s all ease;
}

.intro__btn-img-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.intro__btn-img-second {
  position: absolute;
  top: -0.5%;
  left: -0.5%;
  width: 101%;
  height: 101%;
  opacity: 0;
  transform: rotate(180deg);
}

.intro__btn:hover {
  color: #fff;
}

.intro__btn:hover .intro__btn-img-second {
  opacity: 1;
  transition-delay: 0s;
}

.intro__img-wrap.no-gradient::after {
  background: transparent;
}

.intro__btn-img {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  transition: 0.3s all ease;
  opacity: 1;
  filter: drop-shadow(0px 5px 37px rgba(16, 162, 255, 0.39));
}

.intro__btn-img-second {
  opacity: 0;
}

.intro__btn:hover .intro__btn-img-second {
  opacity: 1;
}

.intro__btn:hover .intro__btn-img:not(.intro__btn-img-second) {
  opacity: 0;
}

.intro__line-img {
  z-index: 1;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* free-cons */
.free-cons {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: max-content;
  background: #fff;
}

.free-cons__inner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.free-cons__slier-menu {
  z-index: 3;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
}

button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.free-cons__slier-arrow-prev {
  margin-right: 24px;
}

svg {
  width: 34px;
  height: 40;
  fill: none;
}

.free-cons__dots-container {
  display: flex;
}

.free-cons__dot {
  width: 27px;
  height: 27px;
  margin: 0 14px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  transition: 0.3s all ease;
}

.free-cons__slier-arrow-next {
  margin-left: 24px;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.free-slider {
  width: 100%;
  height: 510px;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.free-slider__item {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.free-slider__item-contant {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.free-slider__item-img {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.free-slider__item-title {
  width: 100%;
  max-width: 700px;
  line-height: 130%;
  margin-bottom: 24px;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
}

.free-slider__item-text {
  width: 100%;
  max-width: 500px;
  line-height: 130%;
  font-size: 23px;
  font-weight: 400;
  color: #fff;
}

.free-slider__item-btn {
  width: -moz-max-content;
  width: max-content;
  line-height: 130%;
  margin-top: 40px;
  padding: 22px 50px;
  font-size: 21px;
  text-transform: uppercase;
  border: 2px solid #fff;
  border-radius: 52px;
  transition: 0.3s all ease;
}

@media (max-width: 1700px) {
  .intro__btn-container {
      padding: 0 20px;
  }
}

@media (max-width: 1320px) {
  .free-slider__item-btn {
    font-size: 18px;
  }

  .free-slider__item-text {
    font-size: 18px;
  }

  .free-slider__item-title {
    font-size: 35px;
  }
}

@media (max-width: 1000px) {
  .free-slider {
    height: 400px;
  }

  .free-slider__item-img {
    -o-object-position: top right;
    object-position: top right;
    filter: contrast(90%);
  }

  .free-slider__item-btn {
    padding: 14px 30px;
  }

  .free-slider__item-contant::after {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 900px) {
  .intro__img-container {
    flex-direction: column;
    max-height: 900px;
  }

  .intro__img-wrap {
    width: 100%;
    max-height: 300px;
  }

  .intro__btn-container {
    bottom: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }

  .free-slider__item-title {
    font-size: 28px;
  }

  .intro__line-img {
    display: none;
  }
}

@media (max-width: 840px) {
  .intro__btn {
    position: relative;
    top: 165px;
  }
}

@media (max-width: 800px) {
  .free-cons__slier-arrow-prev {
    margin-right: 12px;
  }

  .free-cons__slier-arrow-next {
    margin-right: 12px;
  }

  .free-cons__dot {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }

  .intro {
    margin-bottom: 0;
  }
}

@media (max-width: 651px) {
  .intro__btn {
    width: 50%;
    height: 100px;
    line-height: 100%;
  } 
}

@media (max-width: 600px) {
  .free-slider__item-btn {
    padding: 12px 26px;
    font-size: 12px;
  }
  
  .free-slider__item-title {
    font-size: 22px;
  }

  .free-slider__item-text {
    font-size: 14px;
  }
}